// custom variables
@import "./styles/variables";
// toast
@import "~react-toastify/dist/ReactToastify.min.css";
// Import Flag Icons Set
@import "~flag-icon-css/css/flag-icon.min.css";

// Date picker here
@import "~react-datepicker/dist/react-datepicker.css";

// Import Simple Line Icons Set
@import '~simple-line-icons/css/simple-line-icons.css';
// Bootstrap scss
@import "~bootstrap/scss/bootstrap";
@import "./styles/toast";
// custom dropdown styles
@import "./styles/custom-dropdown";
@import "./styles/react_tooltip";

@import "./styles/hr_custom";

