@import "./variables";
@import "./minimalist-scrollbar";

.dropdown-menu {

  .dropdown-item {
    padding: 0.5rem 0.75rem;

    > i {
      width: 1rem;
      text-align: center;
      margin-right: 0.5rem;
      font-style: normal;
    }
  }

  &.dark {

    @include minimalist-scrollbar;

    background-color: $dark;
    box-shadow: $box-shadow-floating-panels;
    color: $white;
    //border-radius: 0.75rem;

    &.on-dark-background {
      $bg-for-dark: desaturate(lighten($dark, 20%), 30%);
      $outline-for-dark: darken($bg-for-dark, 20%);
      background-color: $bg-for-dark;
      box-shadow: 0 0 5px 0 $outline-for-dark;

      &.with-arrow {
        .dropdown-arrow {
          &[data-placement="bottom-start"], &[data-placement="bottom-end"] {
            &:before {
              border-top-color: $outline-for-dark;
              border-left-color: $outline-for-dark;
              box-shadow: -1.5px -1.5px 2px 0 transparentize($outline-for-dark, 0.5);
            }
          }

          &[data-placement="top-start"], &[data-placement="top-end"] {
            &:before {
              border-bottom-color: $outline-for-dark;
              border-right-color: $outline-for-dark;
              box-shadow: 1.5px 1.5px 2px 0 transparentize($outline-for-dark, 0.5);
            }
          }

          &:before {
            background-color: $bg-for-dark;
            box-shadow: -1.5px -1.5px 2px 0 $outline-for-dark;
          }
        }
      }
    }

    .dropdown-item {
      color: $white;
      margin-top: 0.25rem;
      margin-left: 0.5rem;
      margin-right: 0.5rem;
      width: calc(100% - 1rem);
      border-radius: 0.5rem;
      padding-top: 0.375rem;
      padding-bottom: 0.375rem;

      &.active {
        background-color: $color-floating-panels-selection-bg;
        @include floating-panel-selection-text;
      }

      &:hover,
      &:focus {
        background-color: $color-floating-panels-selection-bg;
      }
    }

    .dropdown-divider {
      border-top-color: lighten($dark, 15%);
    }
  }

  &.with-arrow {
    position: relative;


    &[x-placement="bottom-start"], &[x-placement="bottom-end"] {
      margin-top: 0.75rem;
    }

    &[x-placement="top-start"], &[x-placement="top-end"] {
      margin-bottom: 0.75rem;
    }

    .dropdown-arrow {
      width: 0.75rem;
      height: 0.75rem;
      position: absolute;

      &[data-placement="bottom-start"], &[data-placement="bottom-end"] {
        top: -0.38rem;

        &:before {
          border-left: $dropdown-border-width solid $dropdown-border-color;
          border-top: $dropdown-border-width solid $dropdown-border-color;
        }
      }

      &[data-placement="top-start"], &[data-placement="top-end"] {
        bottom: -0.38rem;

        &:before {
          border-bottom: $dropdown-border-width solid $dropdown-border-color;
          border-right: $dropdown-border-width solid $dropdown-border-color;
        }
      }

      &:before {
        content: '';
        display: block;
        width: 0.75rem;
        height: 0.75rem;
        background-color: $dropdown-bg;
        transform-origin: center center;
        transform: translate(0) rotate(45deg);
        border-color: transparent
      }
    }

    &.dark {
      .dropdown-arrow {
        &[data-placement="bottom-start"], &[data-placement="bottom-end"] {
          &:before {
            border-top: 1px solid $box-shadow-floating-panels-color;
            border-left: 1px solid $box-shadow-floating-panels-color;
            box-shadow: -1.5px -1.5px 2px 0 transparentize($box-shadow-floating-panels-color, 0.5);
          }
        }

        &[data-placement="top-start"], &[data-placement="top-end"] {
          &:before {
            border-bottom: 1px solid $box-shadow-floating-panels-color;
            border-right: 1px solid $box-shadow-floating-panels-color;
            box-shadow: 1.5px 1.5px 2px 0 transparentize($box-shadow-floating-panels-color, 0.5);
          }
        }

        &:before {
          background-color: $color-floating-panels-bg;
        }
      }
    }
  }

  &.big-items {
    .dropdown-item {
      white-space: normal;

      .btn-outline-light {
        border-color: transparentize(#fff, 0.8)
      }
    }
  }
}
