html,
body,
#root {
  min-height: 100vh;
  height: 100%;
  width: 100%;
  //background-color: $theme-background-color;
}

.app,
.app-body,
.main {
  min-height: 100vh;
  height: 100%;
}

.app-body {
  width: 100%;
  min-height: 100%;

  .main {
    z-index: 0;
    transition: padding-left 0.3s;
    position: relative;
    padding-top: 0;
    min-height: 100%;
    width: 100%;
  }
}

.page-loader {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &.dark {
    background-color: $dark;
  }
}

.loader {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadingContent {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.loadingOverlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1001;
  width: 100%;
  height: 100%;
  background-color: $white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.noResults {
  // background-color: $secondary-bg;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2.5rem;
  font-style: italic;
}

.app-flex-center {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fullscreen-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: calc(100vh - 8rem);
  width: 100%;
  max-height: 100%;
}

//override primary color, its calculated by default
.btn-primary {
  color: $white !important;
}

.btn-outline-primary {
  &:hover {
    color: $white !important;
  }
}

.btn-outline-warning {
  &:hover {
    color: $white !important;
  }
}

.btn-secondary {
  color: $white !important;
}

.badge-primary {
  color: $white !important;
}

.badge-secondary {
  color: $white !important;
}

/*
media font size
*/
@media only screen and (max-width: map-get($grid-breakpoints, "sm")) {
  h1 {
    font-size: 1.5rem;
  }
}
