@import "variables";

@media only screen and (max-width: map-get($grid-breakpoints, "sm")) {
  .custom-dark-tooltip {
    max-width: 20rem !important;
  }
}

.custom-dark-tooltip {
  //position: absolute;
  max-width: 50rem;

  &.show {
    background-color: $theme-dark !important;
    border-color: $theme-dark !important;
  }

  &.place-left {
    &:after {
      border-left-color: $theme-dark !important;
    }
  }

  &.place-right {
    &:after {
      border-right-color: $theme-dark !important;
    }
  }

  &.place-top {
    &:after {
      border-top-color: $theme-dark !important;
    }
  }

  &.place-bottom {
    &:after {
      border-bottom-color: $theme-dark !important;
    }
  }
}
