//main theme colors
$theme-green: #97BF0D; //
$theme-green-dark: #006633; // herol logo color -dark
$theme-dark: #384660;
$theme-background-color: #F2F2F2;
$theme-gray: #E4E4E4;

$color-hr: $theme-green;
$color-hr-dark: $theme-green-dark;

$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;

$blue: #007bff !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #dc3545 !default;
$orange: #fd7e14 !default;
$yellow: #ffc107 !default;
//$green: #28a745 !default;
$green: $color-hr !default;
$teal: #20c997 !default;
$cyan: #17a2b8 !default;

// override bootstrap colors
$primary: $color-hr;
$secondary: $gray-500 !default;
$success: $green !default;
$info: $cyan !default;
//$warning: $yellow !default;
$warning: $orange;
$danger: $red !default;
$light: $gray-100 !default;
//$dark: $gray-800 !default;
$dark: $theme-dark !default;

$table-head-color: $white;
$table-head-bg: $theme-dark;

// body
$body-bg: $theme-background-color;

// form
$input-border-color: $theme-dark;

$form-group-margin-bottom: 0.5rem;
$label-margin-bottom: 0.2rem;

// buttons
$btn-border-radius: 0.5rem;
$btn-border-radius-sm: 0.5rem;

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "dark": $dark,
);

$colors: (
  "blue": $primary
);

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

//@import "~bootstrap/scss/functions";
//@import "~bootstrap/scss/variables";
//@import "~bootstrap/scss/mixins";

//// Required
//@import "node_modules/bootstrap/scss/functions";
//@import "node_modules/bootstrap/scss/variables";
//@import "node_modules/bootstrap/scss/mixins";
//
//// Optional
//@import "node_modules/bootstrap/scss/reboot";
//@import "node_modules/bootstrap/scss/type";
//@import "node_modules/bootstrap/scss/images";
//@import "node_modules/bootstrap/scss/code";
//@import "node_modules/bootstrap/scss/grid";


$color-floating-panels-bg: $dark;
$color-floating-panels-selection-bg: desaturate(lighten($dark, 8%), 20%);

$color-panels-bg: $theme-gray;

// left sidebar
$sidebar-open-width: 16rem;
$sidebar-closed-width: 4rem;

//$border-radius-floating-panels: 1rem;
$border-radius-floating-panels: 0;
$box-shadow-floating-panels-color: rgba($gray-400, 0.5);
$box-shadow-floating-panels: 0 0px 4px 1px $box-shadow-floating-panels-color;

$scrollbar-main-color: desaturate(lighten($dark, 30%), 30%);
$scrollbar-thumb-color: transparentize($scrollbar-main-color, 0.33);
$scrollbar-thumb-border-color: transparent;
$scrollbar-bg-color: transparentize($scrollbar-main-color, 0.66);


@mixin floating-panel-selection-text {
  color: desaturate(lighten($color-hr, 3%), 3%);
  text-shadow: 0 0 1px rgba($color-hr, 0.8);
}

@mixin heading-font($font-size) {
  font-family: $headings-font-family;
  // font-style: $headings-font-style;
  font-weight: $headings-font-weight;
  font-size: $font-size;
  @include heading-letter-spacing($font-size)
}

@mixin heading-letter-spacing($font-size) {
  @if $font-size {
    letter-spacing: max(0.05 * $font-size, 0.08rem);
  }
}
